import * as R from 'ramda'
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useNavigate } from '@reach/router'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faCalendarAlt,
  faFilePdf
} from '@fortawesome/free-regular-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import FsLightbox from 'fslightbox-react'

import sanityClient from '../lib/client.js'
import { mapIndexed, notNilOrEmpty, pick } from '../lib/Helpers'
import BlockContent from '../components/BlockContent'
import ContactFormModal from '../components/ContactFormModal'
import BookMeeting from '../components/BookMeeting'
import ExhibitorsGrid from '../components/ExhibitorsGrid'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PreviewPdf from '../components/PreviewPdf'

const HeroSpaceship = require('../assets/imgs/Header3.jpg')
const HeroStars = require('../assets/imgs/stars.jpg')
const HeroStarsEarth = require('../assets/imgs/stars-earth.jpg')
const HeroWorld = require('../assets/imgs/Header2.jpg')

export default props => {
  let fileAssets = [],
    videosURLs = [],
    heroImages = [HeroSpaceship, HeroStars, HeroStarsEarth, HeroWorld]

  const { data } = props

  const { sanityExhibitors: exhibitor } = data
  const { sanitySiteSettings: settings } = data

  const { edges: companies } = data.allSanityExhibitors

  const [token, setToken] = React.useState('')
  const [noCaptchaToken, setNoCaptchaToken] = React.useState('')
  const [filesLightbox, toggleFilesLightbox] = React.useState({
    toggler: false,
    slide: 0
  })

  const [defaultOrder, setDefaultOrder] = React.useState([])
  const [isWhoAreYouImgOpen, setIsWhoAreYouImgOpen] = React.useState(false)
  const [isWhatYouBuildImgOpen, setIsWhatYouBuildImgOpen] = React.useState(
    false
  )
  const [videosLightbox, toggleVideosLightbox] = React.useState(false)
  const [heroImage, setHeroImage] = React.useState()
  const [exhibitorData, setExhibitorData] = React.useState([])

  const navigate = useNavigate()

  const FormModal = withReactContent(Swal)

  const exhibitorQuery = `*[_type == "exhibitors" && slug.current == '${exhibitor.slug.current}'] {
    company_zoom_url
  }`

  // React.useEffect(() => {
  //   notNilOrEmpty(exhibitor) &&
  //     sanityClient
  //       .fetch(exhibitorQuery)
  //       .then(res => setExhibitorData(res[0]))
  //       .catch(err => console.error)
  // }, [exhibitor])

  React.useEffect(() => {
    typeof window !== 'undefined' && window.scrollTo(0, 0)
    loadReCaptcha(process.env.GATSBY_GOOGLE_RECAPTCHA)
    setHeroImage(pick(heroImages))
  }, [])

  function verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!
    setToken(recaptchaToken)
  }

  function handleFilesLightbox(index) {
    toggleFilesLightbox({
      toggler: !filesLightbox.toggler,
      slide: index + 1
    })
  }

  function handleVideoClick() {
    const video = exhibitor.company_videos[0]
    R.cond([
      [R.equals('youtube'), () => toggleVideosLightbox(!videosLightbox)],
      [
        R.T,
        () => {
          if (typeof window !== 'undefined') {
            window.open(video.video_url)
          }
        }
      ]
    ])(video.video_source)
  }

  function setFileAssetsArr(file) {
    fileAssets.push(
      <div className="pdf-fullscreen">
        <PreviewPdf {...file} />
      </div>
    )
  }

  return (
    <Layout>
      <ReCaptcha
        sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA}
        action="homepage"
        verifyCallback={verifyCallback}
      />
      <SEO
        title={`${exhibitor.title} | Afwerx IRIS Challenge Virtual Showcase`}
        keywords={settings.keywords}
        description={exhibitor.title}
        author={settings.author}
        image={exhibitor.company_img.asset.fluid.src}
        url={`${settings.site_url}/exhibitor/${exhibitor.slug.current}`}
      />
      <Hero
        title={R.toUpper(exhibitor.title)}
        className="exhibitor-page"
        img={heroImage}
        subTitle="VIRTUAL BOOTH"
      />
      <div className="exhibitor-page page--booth">
        <section className="section--intro container">
          <div className="section--intro__content">
            <div className="left">
              <Img
                fluid={exhibitor.company_img.asset.fluid}
                objectFit="scale-down"
                objectPosition="50% 50%"
              />
            </div>
            <div className="center">
              {exhibitor.solution_name && (
                <h3 className="challenge-name-title">
                  <span className="color--primary">Solution Name: </span>
                  {exhibitor.solution_name}
                </h3>
              )}
              <p className="keys">
                <span className="color--primary">
                  <strong>Challenge: </strong>
                </span>
                Integrated Respirator Information System
              </p>
            </div>
            <div className="right">
              <a
                href={exhibitor.submission_details_url}
                target="_blank"
                rel="noopener"
                className="btn btn--primary btn--small text--center"
              >
                View Challenge Submission
              </a>
              <p
                className="text--center"
                style={{ lineHeight: 1, marginTop: 5 }}
              >
                <small>
                  You will need to make an AFWERX Challenge account to view.
                </small>
              </p>
            </div>
          </div>
        </section>
        {settings.zoom_links && notNilOrEmpty(exhibitor.company_zoom_url) && (
          <section>
            <div
              className="text--center"
              style={{
                background: `url(${require('../assets/imgs/bg-zoom.jpg')}) left center / cover no-repeat`,
                padding: '4rem 0'
              }}
            >
              <a
                href={exhibitor.company_zoom_url}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn--yellow"
              >
                Meet with Me
              </a>
            </div>
          </section>
        )}
        <section className="section--content bg--off-white">
          <div className="container">
            <h2 className="color--primary uppercase title">
              Solution Description:{' '}
            </h2>
            {exhibitor.bc__who_are_you}
          </div>
        </section>
        {notNilOrEmpty(exhibitor._rawCompanyFileAsset) && (
          <section className="section--content bg--off-white">
            <div className="container">
              <h2 className="color--primary uppercase title text--center">
                Learn More
              </h2>
              <div className="links">
                {mapIndexed((file, i) => {
                  setFileAssetsArr(file)
                  return (
                    <div className="links__pdfs links__icon" key={i}>
                      <PreviewPdf
                        lightbox={() => handleFilesLightbox(i)}
                        {...file}
                      />
                    </div>
                  )
                })(exhibitor._rawCompanyFileAsset)}
              </div>
            </div>
          </section>
        )}
        <section className="section--content bg--primary">
          <div className="container company-contact-details">
            {exhibitor.company_phone && (
              <div className="company-info">
                <p className="keys">
                  <span className="color--yellow">
                    <strong>Contact Name: </strong>
                  </span>
                  <br />
                  {exhibitor.company_primary_contact}
                </p>
                <p className="keys">
                  <span className="color--yellow">
                    <strong>Phone: </strong>
                  </span>
                  <br />
                  <a
                    href={`tel:${exhibitor.company_phone}`}
                    className="link link--white"
                  >
                    {exhibitor.company_phone}
                  </a>
                </p>
              </div>
            )}
            <div className="horizontal-links">
              {exhibitor.company_url && (
                <div className="links links__url outline links__icon">
                  <a
                    className="link link--yellow"
                    href={exhibitor.company_url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faGlobe}
                      mask={['circle']}
                      size="4x"
                    />
                    <span>Visit</span>
                  </a>
                </div>
              )}
              <div className="links links__contact outline links__icon">
                <a
                  onClick={() =>
                    FormModal.fire({
                      title: 'Contact Us',
                      // text: 'Do you want to continue',
                      // icon: 'success',
                      showConfirmButton: false,
                      confirmButtonText: 'Send',
                      showCloseButton: false,
                      // width: 1600,
                      heightAuto: false,
                      padding: '3em',
                      customClass: {
                        popup: 'popup',
                        confirmButton: 'btn btn--black btn-confirm'
                      },
                      html: (
                        <ContactFormModal
                          exhibitorEmail={
                            exhibitor.company_primary_contact_email
                          }
                          exhibitorContact={exhibitor.company_primary_contact}
                          token={token}
                          setNoCaptchaToken={setNoCaptchaToken}
                        />
                      ),
                      preConfirm: () => {
                        var pageTitle = document.title

                        // putting the document title back to original
                      }
                    })
                  }
                  className="link link--yellow"
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    mask={['circle']}
                    size="4x"
                  />
                  <span>Contact</span>
                </a>
              </div>
              {notNilOrEmpty(exhibitor.company_videos) && (
                <div className="links links__contact outline links__icon">
                  <a
                    onClick={() => toggleVideosLightbox(!videosLightbox)}
                    className="link link--yellow"
                  >
                    <FontAwesomeIcon
                      icon={faYoutube}
                      mask={['circle']}
                      size="4x"
                    />
                    <span>Promotional Video</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
      {/*
       * This is the lightbox for the PDF files
       */}
      {/*
       * This is the lightbox for the videos
       */}
      <FsLightbox
        toggler={videosLightbox}
        sources={[
          `https://www.youtube.com/watch?v=${exhibitor.company_videos}`
        ]}
      />
      <FsLightbox
        toggler={filesLightbox.toggler}
        customSources={fileAssets}
        slide={filesLightbox.slide}
      />
      <section className="section--exhibitors">
        <div className="container">
          <h3>KEEP EXPLORING</h3>
        </div>
        <ExhibitorsGrid />
      </section>
    </Layout>
  )
}

export const exhibitorQuery = graphql`
  query ExhibitorTemplateQuery($_id: String!) {
    sanitySiteSettings {
      author
      keywords
      site_url
      title
      zoom_links
    }
    sanityExhibitors(_id: { eq: $_id }) {
      _rawCompanyFileAsset(resolveReferences: { maxDepth: 10 })
      booth_hero_img {
        asset {
          url
        }
      }
      company_url
      company_phone
      company_videos
      company_primary_contact
      company_primary_contact_email
      company_img {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      submission_details_url
      solution_name
      bc__who_are_you
      slug {
        current
      }
      title
    }
    allSanityVendorType {
      edges {
        node {
          title
        }
      }
    }
    allSanityExhibitors {
      edges {
        node {
          title
          booth_hero_img {
            asset {
              url
            }
          }
          company_img {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 300) {
                ...GatsbySanityImageFixed
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
